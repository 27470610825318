import React from "react"
import { graphql } from "gatsby"
import HalfHeader from "../components/halfBGImageMD"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <HalfHeader
          data={post.frontmatter.background}
          title={post.frontmatter.title}
        />
        <article className="post-content page-template no-image">
          <h2 id="this-is-a-section">{post.frontmatter.description}</h2>

          <div className="post-content-body">
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($locale: String!, $slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      id
      body
      frontmatter {
        title
        description
        background {
          mobileImage: childImageSharp {
            fluid(maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          desktopImage: childImageSharp {
            fluid(maxHeight: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
